<template>
  <section class="contact p-3" id="contact-form" tabindex="0" @focus="handleFocus">
    <div class="row">
      <div class="col-md-7">
        <div class="image-bg h-100"></div>
      </div>
      <div class="col-md-5 p-4 pr-md-5 pb-5 pt-5">
        <div class="py-5">
          <h2 class="w-fw-500 mb-5">Kontakt</h2>
          <h3 class="mb-0">Nikola Šulhaňová</h3>
          <p class="mb-0">Sales Manager</p>
          <p class="mb-0"><a href="tel:+421910187634" class="text-white">+421 910 187 634</a></p>
          <p class="mb-5"><a href="mailto:sulhanova@pentarealestate.com" class="text-white">sulhanova@pentarealestate.com</a></p>
          <h3 class="mb-0">Kamila Kordošová</h3>
          <p class="m-0">Sales Manager</p>
          <p class="mb-0"><a href="tel:+421903590023" class="text-white">+421 903 590 023</a></p>
          <p class="mb-3"><a href="mailto:kordosova@pentarealestate.com" class="text-white">kordosova@pentarealestate.com</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import VueRecaptcha from 'vue-recaptcha'
import cookies from '@/plugins/cookie-banner/getCookie'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { email, required, requiredIf, minLength, helpers } from '@vuelidate/validators';
// import { helpers } from 'vuelidate/lib/validators'
import { useVuelidate } from '@vuelidate/core';
const alpha = helpers.regex(/^[a-zA-Z \u00C0-\u024F]*$/)
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "ContactForm",
  props: ['internalId', 'stage'],
  data() {
    return {
      state: null,
      yourValue: null,
      dropdownActive: false,
      recaptchaActive: false,
      vuePhone: {
        phone: "",
        isValid: false,
        props: {
          clearable: true,
          preferredCountries: ["SK", "CZ", "AT", "PL"],
          defaultCountryCode: "SK",
          noExample: false,
          noFlags: true,
          error: false,
          translations: {
            countrySelectorLabel: "Kód krajiny",
            countrySelectorError: "Chyba",
            phoneNumberLabel: "Telefónne číslo",
            example: "Napríklad:"
          }
        }
      },
      contactForm: {
        "ga_client_id": "",
        "name": "",
        "surname": "",
        "email": "",
        "phone": "",
        "interests": [],
        "marketing_consent": false,
        "g-recaptcha-response": "",
        "internal_id": ""
      },
      validation: false,
      interestsError: false
    }
  },
  validations() {
    return {
      contactForm: {
        name: {
          required,
          minLength: minLength(3),
          onlyString: alpha
        },
        surname: {
          required,
          minLength: minLength(3),
          onlyString: alpha
        },
        email: { required, email },
        interests: {
           required: requiredIf(function() {
            return this.internalId ? false : true;
           })
        }
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v$.contactForm[name];
      return $dirty ? !$error : null;
    },
    onUpdate: function(payload) {
      if (payload.isValid) {
        this.vuePhone.props.error = false
        this.vuePhone.isValid = true
      }
      this.contactForm["phone"] = payload.formattedNumber;
    },
    handleFocus() {
      if (this.dropdownActive) {
        this.dropdownActive = !this.dropdownActive
      }
    },
    showDropdown() {
      this.dropdownActive = !this.dropdownActive;
    },
    onVerify: function (response) {
      this.contactForm["g-recaptcha-response"] = response
    },
    async sendForm(e) {
      if (!this.recaptchaActive) {
        this.recaptchaActive = true
        return
      }
      const result = await this.v$.$validate()
      if (!result || !this.vuePhone.isValid) {
        if (!this.vuePhone.isValid) {
          this.vuePhone.props.error = true
          return
        }
        return
      }
      
      if (cookies.getCookieByName("first_interaction")) {
        this.contactForm["first_interaction_source"] = cookies.getCookieByName("first_interaction")['utm_source']
        this.contactForm["first_interaction_medium"] = cookies.getCookieByName("first_interaction")['utm_medium']
        this.contactForm["first_interaction_campaign"] = cookies.getCookieByName("first_interaction")['utm_campaign']
        this.contactForm["first_interaction_content"] = cookies.getCookieByName("first_interaction")['utm_content']
        this.contactForm["first_interaction_gclid"] = cookies.getCookieByName("first_interaction")['gclid']
        this.contactForm["first_interaction_fbclid"] = cookies.getCookieByName("first_interaction")['fbclid']
      }

      if (cookies.getCookieByName("last_interaction")) {
        this.contactForm["last_interaction_source"] = cookies.getCookieByName("last_interaction")['utm_source']
        this.contactForm["last_interaction_medium"] = cookies.getCookieByName("last_interaction")['utm_medium']
        this.contactForm["last_interaction_campaign"] = cookies.getCookieByName("last_interaction")['utm_campaign']
        this.contactForm["last_interaction_content"] = cookies.getCookieByName("last_interaction")['utm_content']
        this.contactForm["last_interaction_gclid"] = cookies.getCookieByName("last_interaction")['gclid']
        this.contactForm["last_interaction_fbclid"] = cookies.getCookieByName("last_interaction")['fbclid']
      }
      
      this.contactForm["internal_id"] = this.internalId ? this.internalId : ""
      if (this.stage) {
        this.contactForm.interests = [this.stage]
      }
      this.contactForm['ga_client_id'] = cookies.getCookieByName('_ga') ? cookies.getCookieByName('_ga').split('.').slice(-2).join(".") : 0

      const form = this.contactForm
      e.preventDefault()
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/contact-form`, form)
          .then(() => {
            this.contactForm.name = ""
            this.contactForm.surname = ""
            this.contactForm.email = ""
            this.contactForm.phone = ""
            this.contactForm.interests = []
            this.contactForm['marketing_consent'] = false
            this.contactForm["g-recaptcha-response"] = ""
            this.contactForm["internal_id"] = ""
            this.$router.push({
              name: 'FormularOdoslany'
            })
          }).catch((error) => {
            this.$refs.recaptcha.reset()
            // handle error
            console.log(error.response.data.error);
            this.interestsError = !!error.response.data.error.interests;
            this.validation = true
      })
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.name-wrapper
  gap: 0 8px

.dropdown
  background: #fff
  padding: 14px 8px
  color: #6C757D
  cursor: pointer

.recaptcha
  display: none

  &.active
    display: block

.dropdown-content
  background: #fff
  padding: 8px 12px
  border-top: 0.5px solid rgba(0, 0, 0, 0.2)
  position: absolute
  width: 100%
  z-index: 999
  display: none

  &.active
    display: block

  .check-label
    display: block
    color: #6C757D
    font-weight: 400
    cursor: pointer
    margin: 0

    &:hover
      background: rgba(124, 182, 217, 0.15)

.custom-alert-box
  p
    margin-bottom: 0
    font-size: 16px
    font-weight: normal

.contact
  background: #576675
  h2
    font-size: 55px
    line-height: 60px
    margin-bottom: 20px
    margin-top: 16px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    @media (max-width: $md)
      display: inline
      padding-right: 5px
  .image-bg
    background: url(../assets/images/contact-right.jpg) center/cover no-repeat
  .bv-no-focus-ring
    &:focus
      outline: none
  form
    .text-small
      font-size: 14px
      line-height: 20px
    .check-label
      font-size: 16px
      @media (max-width: $md)
        font-size: 14px
    .form-control
      height: 50px
      border: none
    .col-form-label
      margin-bottom: 5px
      font-size: 18px
    .custom-control-label
      font-size: 18px
    .small-font
      .custom-control-label
        font-size: 14px
    a
      color: var(--blue)
      font-size: 14px
      font-weight: 500
    button
      @media (max-width: $md)
        width: 100%
</style>