<template>
  <div>
      <div class="cookies-page">
          <div class="container">
              <div class="row">
                <div id="cookies" class="pt-5">
                    
                </div>
              </div>
          </div>
      </div>
      <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  components: {ContactForm},
  mounted() {
    this.loadCookieDeclarationScript();
  },
  methods: {
    loadCookieDeclarationScript() {
      const targetElement = document.getElementById('cookies');
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = 'https://consent.cookiebot.com/b8510af7-914c-4984-a47b-2951b2ddd73d/cd.js';
      script.async = true;
      targetElement.appendChild(script);
    }
  }
}
</script>

<style lang="sass" scoped>
.cookies-page
    padding-top: 85px
    background: #2D323A

    h1
        @media (max-width: 768px)
            font-size: 30px

    h3
        font-size: 22px

    p
        font-size: 16px
        font-weight: 400

    a 
        color: var(--blue)
</style>
