<template>
  <div>
    <section class="news-section p-md-5">
      <div class="news-header d-block d-md-flex justify-content-between">
        <h3>Čo je nové na Boroch</h3>
        <a href="/novinky" class="btn btn-primary rounded-0 d-none d-md-inline">Zobraziť všetky novinky</a>
      </div>
      <div class="news-wrapper">
        <slick
            ref="slick"
            :options="slickOptions"
        >
          <div class="news" v-for="(item, idx) in filteredData" :key="idx">
            <router-link class="link-to-detail" :to="'/novinky/' + item.slug">link</router-link>
            <div class="image">
              <img :src="item.thumbImage" alt="">
            </div>
            <div class="text">
              <div class="category">{{item.category}}</div>
              <h3 class="title" v-html="item.title"></h3>
            </div>
            <div class="more-link">Zobraziť články</div>
          </div>
        </Slick>
      </div>
      <a href="/novinky" class="btn btn-primary rounded-0 d-inline-block d-md-none mt-4">Zobraziť všetky novinky</a>
    </section>
  </div>
</template>

<script>
import Slick from "vue-slick";
import news from "@/store/news.json"

export default {
  name: "NewsSlider",
  components: {
    Slick
  },
  data() {
    return {
      news: news,
      slickOptions: {
        slidesToShow: 4,
        infinite: false,
        // Any other options that can be got from plugin documentation
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              arrows: false
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      },
    };
  },
  computed: {
    filteredData() {
      return this.news.items.filter(item => {
        return item.visible !== false
      })
    }
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  },
}
</script>

<style lang="sass" scoped>
$md: 768px
.news-section
  background: #21252B
  padding-bottom: 100px !important
  @media (max-width: $md)
    padding: 30px
    padding-right: 0
    padding-bottom: 40px!important
    overflow: hidden
  .news-header
    margin-bottom: 40px

    @media (max-width: $md)
      flex-direction: column
  .news-header>h3
    font-weight: 400
    font-size: 40px
    line-height: 45px
    letter-spacing: -0.3px
    margin-bottom: 0

    @media (max-width: $md)
      margin-bottom: 20px
      max-width: 210px
  img
    display: block
    max-width: 100%
    .col-3
      position: relative
  .news
    position: relative
    .image
      position: relative
      &:after
        content: ""
        width: 100%
        height: 100%
        background: linear-gradient(13.45deg, rgba(33, 37, 43, 0.7) 29.2%, rgba(33, 37, 43, 0) 62.19%)
        position: absolute
        left: 0
        top: 0
    &:hover
      .image
        &:after
          background: rgba(33, 37, 43, 0.6)
      .text
        bottom: 50px

      .more-link
        &:after
          right: -20px
    .link-to-detail
      position: absolute
      width: 100%
      height: 100%
      text-indent: -9999999px
      left: 0
      top: 0
      z-index: 999
  .more-link
    position: absolute
    left: 30px
    bottom: 20px

    @media (max-width: $md)
      font-size: 14px
      left: 20px

    &:after
      content: ""
      width: 5px
      height: 9px
      background: url(../assets/icons/arrow.png) no-repeat
      background-size: contain
      position: absolute
      right: -15px
      top: 8px
      transition: all .3s ease
  .text
    position: absolute
    left: 30px
    bottom: 30px
    height: 120px
    transition: all .3s ease
    @media (max-width: $md)
      bottom: 15px
      left: 20px
    .category
      color: #77B9D9
      font-size: 16px
      line-height: 26px
      letter-spacing: -0.3px
      font-weight: 500
      margin-bottom: 5px
    .title
      font-size: 20px
      line-height: 24px
      letter-spacing: -0.5px
      font-weight: 500
      padding-right: 20px

      @media (max-width: $md)
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
</style>