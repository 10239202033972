<template>
  <div>
    <div class="home d-flex">
      <video playsinline autoplay muted loop poster="../assets/video/bory-video-cover.jpeg" id="bgvid">
        <source src="../assets/video/nadvorie.mp4" type="video/mp4">
      </video>
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Bory Nádvorie</h1>
          <p>Nová etapa v&nbsp;dynamickej časti štvrti Bory, ktorá ponúka výbornú <br>občiansku vybavenosť v&nbsp;pešej dostupnosti. Obchody, služby, kino či fitness centrum len pár minút od polouzavretého vnútrobloku.</p>
          <b-button @click="scrollTo('district')" class="d-block d-md-inline-block" squared variant="primary">Zistiť viac</b-button>
          <b-button href="https://mollyinvr.com/projects/penta/nadvorie/?project=EXT" target="_blank" class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0 btn btn-white">Virtuálna prehliadka exteriérov</b-button>
          <b-button href="https://mollyinvr.com/projects/penta/nadvorie/" target="_blank" class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0 btn btn-white">Virtuálna prehliadka interiérov</b-button>
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5" id="district">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Mesto tak, ako ho poznáte</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.</p>
        </div>
      </div>
      <div id="mapbox" class="position-relative">
        <B5Map/>
        <div id="vr-badge">
          <a href="https://mollyinvr.com/projects/penta/nadvorie/" target="_blank">
            <div class="d-flex flex-column align-items-center justify-content-center p-4 text-center box">
              <img src="../assets/icons/360.svg">
              <p>Prejdite sa vo VR</p>
            </div>
          </a>
        </div>
      </div>
    </section>

    <section id="tabulka">
      <FlatsTableTemp :stages="['bory3b']" :showStageAndDate="false" :show-vr="true"></FlatsTableTemp>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">Harmonogram výstavby</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>Začiatok<br> výstavby</h3>
          <div class="line fill-min"></div>
          <p class="date">3. kvartál 2025</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>Žiadosť<br> o kolaudáciu</h3>
          <div class="line"></div>
          <p class="date">4. kvartál 2027</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>Kolaudačný<br> proces</h3>
          <div class="line"></div>
          <p class="date">1. kvartál 2028</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>Odovzdávanie<br> bytov</h3>
          <div class="line"></div>
          <p class="date">2. - 3. kvartál 2028</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3>Vaše<br> bývanie</h3>
          <div class="line transparent"></div>
        </div>
      </div>
    </section>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-center top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Prejdite sa projektom <br>vo virtuálnej realite</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Pomocou virtuálnej reality sa môžete <br>prejsť areálom ale aj jednotlivými bytmi <br>projektu Bory Nádvorie už dnes.</p>
        </div>
      </div>
      <div id="mapbox" class="position-relative">
        <div id="bory-vr">
          <div id="woowstudio" data-zoom="disablewheel"></div>
        </div>
      </div>
    </section>

    <section class="section-3 p-3" id="section-1">
      <div class="">
        <div class="row px-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3 pb">
            <h2>Vyskúšajte virtuálnu realitu interiéru</h2>
            <p class="mb-5">Prejdite sa interiérom vami vybraného bytu pomocou virtuálnej reality a presvedčte sa o jeho skvelej dispozícii už dnes.</p>
            <b-button href="https://mollyinvr.com/projects/penta/nadvorie/" class="d-block d-md-inline-block mb-4" target="_blank" squared variant="primary">Virtuálna prehliadka interiéru</b-button>
            <div class="d-none d-md-block">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-4 py-4">
          <h2 class="mb-4">Domov, ktorý oslní</h2>
          <p class="mb-3">Doma je tam, kde sa cítite sami sebou. Aj preto si môžete vybrať z&nbsp;viacerých úrovní štandardov, ktoré pre vás precízne vytvárame tak, aby naplnili očakávania aj náročných klientov. Samozrejmosťou sú kvalitné materiály a&nbsp;rôzne farebné prevedenia.</p>
        </div>
        <div class="col-md-8 pb-3 pb-md-0 pr-md-0">
          <div class="right-img bg-img-2"></div>
        </div>
      </div>
    </section>

    <section class="section-3 p-3">
      <div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>Atraktívna zeleň</h3>
              <p>Zelené zóny projektované pre deti aj dospelých zabezpečia kvalitné trávenie voľného času aj priestor na oddych.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>Detské ihriská</h3>
              <p>Premyslene navrhnuté detské ihriská, pre bezstarostné hry detí, situované v&nbsp;bezpečnej časti bez áut</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>Hrádza aj škôlka na skok</h3>
              <p>Ranný beh či popoludňajšie korčuľovanie len na skok od vášho bytu. Rovnako aj škôlka, ktorá sa nachádza 5&nbsp;minút pešo.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <h2 class="mb-4">Buďte v&nbsp;centre <br>diania</h2>
          <p class="mb-5">Etapa Bory Nádvorie sa vyznačuje <br>premysleným architektonickým riešením <br>dvoch budov, ktoré spoločne vytvárajú <br>príjemný vnútroblok. Ten zaručí dostatočné <br>súkromie s&nbsp;prirodzeným prepojením do celej štvrte.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FlatsTableTemp from "@/components/FlatsTableTemp";
import B5Map from "@/components/maps/B5Map";

export default {
  name: "Bory5",
  components: {B5Map, FlatsTableTemp},
  data() {
    return {
      slickOptions: {
        infinite: false,
        adaptiveHeight: true,
        dots: false,
        arrows: true
        // Any other options that can be got from plugin documentation
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 2
        //     }
        //   }
        //   // You can unslick at a given breakpoint now by adding:
        //   // settings: "unslick"
        //   // instead of a settings object
        // ]
      },
    }
  },
  methods: {
    toggleBox(val) {
      console.log(val)
      document.querySelector('.' + val).classList.toggle('clicked')
      document.querySelector('[data-box="' + val + '"]').classList.toggle('show')
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      const offset = 80; // Adjust this value to set the offset in pixels
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

#mapbox
  padding: 0

video
  object-fit: cover
  width: 100vw
  height: 100vh
  position: absolute
  top: 0
  left: 0

#vr-badge
  position: absolute
  width: 140px
  height: 140px
  background: #fff
  border-radius: 50%
  bottom: 15%
  right: 10%
  z-index: 99
  cursor: pointer

  @media (max-width: $md)
    width: 110px
    height: 110px
    bottom: 20px
    right: 20px

  img
    max-width: 70%
    margin-bottom: 10px

  p
    color: #000
    font-size: 16px

    @media (max-width: $md)
      font-size: 14px

#bory-vr
  width: 100%
  height: 80vh
  background: #ccc
  position: relative
  box-sizing: border-box

#woowstudio
  position: absolute
  width: 100%
  height: 100%
  padding: 0
  margin: 0
  box-sizing: border-box

.flat-models
  background: var(--text-dark)
  position: relative
  padding-bottom: 100px
  overflow: hidden

  .models-info-box
    background: #fff
    position: absolute
    right: -100%
    padding: 15px 20px
    bottom: 30px
    transition: all .5s ease

    @media (max-width: $md)
      position: static

    &.active
      right: 50px

    p
      color: #21252b

    .btn
      &:hover
        background: var(--primary)
        border-color: var(--primary)

  .model-wrapper
    display: flex
    margin-left: -4px
    margin-right: -4px

    @media (max-width: $md)
      flex-direction: column
    .flat
      width: 20%
      height: 0
      padding-bottom: 20%
      position: relative

      @media (max-width: $md)
        width: 100%
        padding-bottom: 100%

      &:hover
        &:after
          background: rgba(0, 0, 0, .2)

        .button
          opacity: 1

      &::after
        content: ""
        width: 100%
        height: 100%
        background: rgba(0, 0, 0, .5)
        position: absolute
        left: 0
        top: 0

      // &.flat-1
      //   background: url(../assets/images/bh3_viz/jednoizbovy.jpg) no-repeat
      //   background-size: cover
      //   background-position: center

      // &.flat-2
      //   background: url(../assets/images/bh3_viz/dvojizbovy.jpg) no-repeat
      //   background-size: cover
      //   background-position: center
      
      // &.flat-3
      //   background: url(../assets/images/bh3_viz/trojizbovy.jpg) no-repeat
      //   background-size: cover
      //   background-position: center

      // &.flat-4
      //   background: url(../assets/images/bh3_viz/stvorizbovy.jpg) no-repeat
      //   background-size: cover
      //   background-position: center

      // &.flat-5
      //   background: url(../assets/images/bh3_viz/patizbovy.jpg) no-repeat
      //   background-size: cover
      //   background-position: center

      .box
        z-index: 1
        margin-top: 50%

        @media (max-width: $md)
          margin-top: 30%

        p
          font-size: 24px

  .models-wrapper
    &:hover
      .model-box:not(:hover)
        opacity: .3

  .model-box
    width: 20%
    padding: 10px
    transition: all .3s ease

    @media (max-width: $md)
      width: 100%

    &:hover
      .button
        opacity: 1

    .model
      display: flex
      align-items: center
      justify-content: center
      padding: 0

      @media (max-width: $md)
        height: auto !important

      img
        object-fit: contain
        // max-height: 80%

        @media (max-width: $md)
          height: auto !important

      // &.model-1
      //   img
      //     height: 188px

      // &.model-2
      //   img
      //     height: 266px

      // &.model-3
      //   img
      //     height: 230px

      // &.model-4
      //   height: 520px
      //   img
      //     height: 400px

      // &.model-last
      //   height: 520px
      //   img
      //     height: 420px

  .title
    font-size: 28px
    text-align: center
    font-weight: 400
    letter-spacing: -0.24px
    margin-bottom: 70px
    padding-left: 30px
    line-height: 28px

    @media (max-width: $md)
      padding-left: 0
      font-size: 20px

  .box
    text-align: center
    position: relative

    .button
      opacity: 0
      position: relative
      padding-right: 55px
      background: #fff
      color: #001F33

      @media (max-width: $md)
        opacity: 1

      &:before
        content: ""
        width: 27px
        height: 16px
        background: url("../assets/icons/360.svg") center/contain no-repeat
        position: absolute
        right: 15px
        top: 50%
        transform: translateY(-50%)

    @media (max-width: $md)
      padding: 20px 20px 40px
      margin-bottom: 15px

    &:hover
      border-color: var(--blue)
      .link
        color: var(--blue)

    .link
      position: absolute
      right: 15px
      bottom: 15px
      font-size: 14px

    .icon
      height: 100px
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 40px

    p
      font-size: 18px
      font-weight: 500
      margin-bottom: 40px

    .text-blue
      color: var(--blue)
      font-weight: 700
      font-size: 20px
      line-height: 24px
      margin-bottom: 0

#standards-slider
  max-width: 100%
  overflow: hidden
  .slide
    position: relative
  img
    height: 100%
  .na-hradzi
    .slide-1
      .standards-info
        .button-toggle
          &.info-1
            left: 8%
            top: 80%
          &.info-2
            top: auto
            bottom: 3%
            left: 56%
          &.info-3
            top: 30%
            left: 90%
    .slide-2
      .standards-info
        .button-toggle
          &.info-2-1
            left: 23%
            top: 25%
          &.info-2-2
            top: auto
            bottom: 25%
            left: 22%
          &.info-2-3
            top: 12%
            left: 38%
    .slide-3
      .standards-info
        .button-toggle
          &.info-3-1
            left: 62%
            top: 40%
          &.info-3-2
            top: 58%
            left: 62%
          &.info-3-3
            top: 50%
            left: 93%
    .slide-4
      .standards-info
        .button-toggle
          &.info-4-1
            left: 21%
            top: auto
            bottom: 15%
          &.info-4-2
            top: auto
            bottom: 10%
            left: 48%
          &.info-4-3
            top: auto
            bottom: 18%
            left: 80%
    .slide-5
      .standards-info
        .button-toggle
          &.info-5-1
            left: 43%
            top: 50%
          &.info-5-2
            top: auto
            bottom: 20%
            left: 52%

.na-hradzi
  .slide-1
    .standards-info
      .box
        &.box-1
          top: -120px
          left: 50px
        &.box-2
          top: auto
          bottom: 50px
          left: -160px
        &.box-3
          top: 0
          left: auto
          right: 50px
  .slide-2
    .standards-info
      .box
        &.box-1
          top: 50px
          right: -160px
        &.box-2
          top: 0
          left: 50px
        &.box-3
          top: 0
          left: 50px
  .slide-3
    .standards-info
      .box
        &.box-1
          bottom: 0
          left: auto
          right: 50px
        &.box-2
          top: 0
          left: auto
          right: 50px
        &.box-3
          bottom: 0
          left: auto
          right: 50px
  .slide-4
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 55px
          left: -160px
        &.box-2
          top: auto
          bottom: 55px
          left: -160px
        &.box-3
          top: auto
          bottom: 55px
          left: -160px
  .slide-5
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 0
          left: 55px
        &.box-2
          top: -90px
          left: auto
          right: 55px
.standards-info
  .box
    display: none
    position: absolute
    background: rgba(255, 255, 255, .8)
    width: 370px
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)

    &.show
      display: block

    h4, p
      color: var(--darkBlue)

    h4
      font-size: 16px
      font-weight: bold

    p
      margin-bottom: 0
      font-size: 14px
  .button-toggle
    position: absolute
    width: 44px
    height: 44px
    background: var(--blue)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) rotate(360deg)
    border-radius: 50%
    cursor: pointer
    z-index: 100
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)
    transition: 0.8s cubic-bezier(0.2, 0.6, 0.3, 1.1)

  .button-toggle:after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 2px
    width: 50%
    background: white

  .button-toggle:before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 50%
    width: 2px
    background: white

  .button-toggle.clicked
    //transform: translate(-50%, -50%) rotate(180deg)
    background: var(--textDark)

  .button-toggle.clicked:before
    width: 0

.about-bottom-2
  .bg
    background: url('../assets/images/bory5/06H_BH3B_static_ext_round_02.jpg') top/cover no-repeat

  .signature-section
    h2
      max-width: 570px
      @media (max-width: $md)
        font-size: 28px
        line-height: 40px
        letter-spacing: -1px
    p
      max-width: 570px

    .btn
      align-self: flex-start
.about-bottom
  padding-bottom: 11px!important
  .right-img
    background: url(../assets/images/bory3/bory3-domov.jpg) center/cover no-repeat
  .bg-img-2
    background: url("../assets/images/bory5/01C.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover

.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-half, &.fill-min
      &:before
        background: var(--blue)
    &.fill-min
      &:after
        width: 15%
    &.transparent
      background: transparent

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: url("../assets/images/nadvorie_konfig.jpg") top/cover no-repeat
    background-position: center
  
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 480px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      max-width: 320px
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory5/05P_BH3B_static_ext_round_01.jpg") center/cover no-repeat
    background-position: left center

    @media (max-width: $md)
      background-position: center
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory5/01E_BH3B_static_ext_round_02.jpg") bottom/cover no-repeat
    background-position: -200px center
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory5/04D_BH3B_static_ext_round_02.jpg") center/cover no-repeat
    background-position: left center
    @media (max-width: $md)
      background-position: center
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory5/01E_BH3B_static_ext_round_02.jpg") bottom/cover no-repeat
  .intro-text
    position: relative
  p
    margin-bottom: 30px
    max-width: 770px

  .btn-white
    background: #fff
    color: #001F33
    border-radius: 0
    border-color: #fff
    position: relative
    // padding-left: 55px

    // &:before
    //   content: ""
    //   width: 27px
    //   height: 16px
    //   background: url("../assets/icons/360.svg") center/contain no-repeat
    //   position: absolute
    //   left: 15px
    //   top: 50%
    //   transform: translateY(-50%)

    &:hover
      background: #CFEDFF
      border-color: #CFEDFF

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
