<template>
    <div class="new-map">
        <img src="../../assets/images/HomePageMapa/default2_map.jpg" alt="Bory štvrť" id="stvrt-default">
        <img src="../../assets/images/HomePageMapa/nahradzi2_map.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="hradza">
        <img src="../../assets/images/HomePageMapa/novydvor2_map.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="novydvor">
        <img src="../../assets/images/HomePageMapa/promenada2_map.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="promenada">
        <img src="../../assets/images/HomePageMapa/prvedomy2_map.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="prvedomy">
        <svg :path-enter="pathEnter" :path-leave="pathLeave" class="overlay" width="2400px" height="1570px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 2400 1570" style="enable-background:new 0 0 2400 1570;" xml:space="preserve">
        <g id="Layer_2" class="d-none d-md-block">
            <a href="./byvanie/na-hradzi">
                <polygon @mouseover="showImg('hradza')" @mouseout="setDefault('hradza')" class="st0" points="428.3,369 404.3,451.6 428.3,454.4 541.1,430.9 604.1,411.7 574.6,350.6 518.2,355.6 518.2,278 
                    546.1,269.6 562.3,254 572.3,240.1 577.4,216.1 572.9,190.9 561.2,170.8 544.4,157.4 523.8,150.2 507,150.2 487.5,154.7 
                    470.8,164.1 456.8,182.6 449.5,202.7 450.1,219.4 452.9,236.7 460.1,252.9 470.2,262.9 480.8,270.2 495.3,275.2 507,277.4 
                    506.5,290.8 504.2,316.5 504.2,357.8 	" fill="transparent"/>
            </a>
            <a href="./byvanie/bory1">
                <polygon @mouseover="showImg('prvedomy')" @mouseout="setDefault('prvedomy')" class="st0" points="1263.3,689.4 1038.4,700.5 1023.3,803.2 1367.7,800.7 1348.7,683.8 1275.6,688.2 1275.6,635.2 
                    1297.4,628.5 1311.9,619 1325.8,605.6 1332.5,587.2 1333.6,557.6 1325.3,537.5 1309.1,520.2 1288.4,508 1275.6,508 1257.2,508 
                    1234.3,516.9 1217,533.1 1208.1,552.6 1205.8,576.1 1211.4,597.8 1222,615.7 1238.8,630.2 1258.3,634.7 	" fill="transparent"/>
            </a>
            <a href="./byvanie/bory3">
                <polygon @mouseover="showImg('promenada')" @mouseout="setDefault('promenada')" class="st0" points="1020,828.9 1020,948.9 1381.1,948.9 1366,816.1 1076.9,816.1 1076.9,782 1101.4,775.9 1118.8,761.9 
                    1131.6,741.8 1136.6,715 1131,691.6 1118.2,672.6 1098.1,657.5 1074.1,652.5 1052.9,655.3 1030,666.5 1012.1,688.2 1007.1,710 
                    1011,739 1022.2,758.6 1039.5,774.8 1059.6,780.9 1061.3,814.9 	" fill="transparent"/>
            </a>
            <a href="./byvanie/novy-dvor">
                <polygon @mouseover="showImg('novydvor')" @mouseout="setDefault('novydvor')" class="st0" points="400.7,474.8 436.7,659.8 689.5,597.8 617.5,442.1 635.1,437.1 651,427 662.8,412 670.3,391 
                    670.3,368.4 664.4,349.2 652.7,334.9 634.3,320.7 615,315.7 594.9,315.7 570.7,324.9 554.8,339.1 545.1,355.9 541.4,378.5 
                    545.1,394.4 550.6,410.3 560.6,422.8 571.5,433.7 	" fill="transparent"/>
            </a>
        </g>
        <g id="Layer_2_mobile" class="d-block d-md-none">
            <polygon @mouseover="showImg('hradza')" @mouseout="setDefault('hradza')" class="st0" points="428.3,369 404.3,451.6 428.3,454.4 541.1,430.9 604.1,411.7 574.6,350.6 518.2,355.6 518.2,278 
                546.1,269.6 562.3,254 572.3,240.1 577.4,216.1 572.9,190.9 561.2,170.8 544.4,157.4 523.8,150.2 507,150.2 487.5,154.7 
                470.8,164.1 456.8,182.6 449.5,202.7 450.1,219.4 452.9,236.7 460.1,252.9 470.2,262.9 480.8,270.2 495.3,275.2 507,277.4 
                506.5,290.8 504.2,316.5 504.2,357.8 	" fill="transparent"/>
            <polygon @mouseover="showImg('prvedomy')" @mouseout="setDefault('prvedomy')" class="st0" points="1263.3,689.4 1038.4,700.5 1023.3,803.2 1367.7,800.7 1348.7,683.8 1275.6,688.2 1275.6,635.2 
                1297.4,628.5 1311.9,619 1325.8,605.6 1332.5,587.2 1333.6,557.6 1325.3,537.5 1309.1,520.2 1288.4,508 1275.6,508 1257.2,508 
                1234.3,516.9 1217,533.1 1208.1,552.6 1205.8,576.1 1211.4,597.8 1222,615.7 1238.8,630.2 1258.3,634.7 	" fill="transparent"/>
            <polygon @mouseover="showImg('promenada')" @mouseout="setDefault('promenada')" class="st0" points="1020,828.9 1020,948.9 1381.1,948.9 1366,816.1 1076.9,816.1 1076.9,782 1101.4,775.9 1118.8,761.9 
                1131.6,741.8 1136.6,715 1131,691.6 1118.2,672.6 1098.1,657.5 1074.1,652.5 1052.9,655.3 1030,666.5 1012.1,688.2 1007.1,710 
                1011,739 1022.2,758.6 1039.5,774.8 1059.6,780.9 1061.3,814.9 	" fill="transparent"/>
            <polygon @mouseover="showImg('novydvor')" @mouseout="setDefault('novydvor')" class="st0" points="400.7,474.8 436.7,659.8 689.5,597.8 617.5,442.1 635.1,437.1 651,427 662.8,412 670.3,391 
                670.3,368.4 664.4,349.2 652.7,334.9 634.3,320.7 615,315.7 594.9,315.7 570.7,324.9 554.8,339.1 545.1,355.9 541.4,378.5 
                545.1,394.4 550.6,410.3 560.6,422.8 571.5,433.7 	" fill="transparent"/>
        </g>
        <g class="bars d-none d-md-block">
            <SvgBar v-if="showBar.b1" :bar="b1bar" :show-bar="showBar.b1" :path-enter="pathEnter" :map-id="7" :path-leave="pathLeave"/>

            <SvgBar v-if="showBar.b2" :bar="b2bar" :show-bar="showBar.b2" :path-enter="pathEnter" :map-id="1" :path-leave="pathLeave" :path-click="pathClick"/>

            <SvgBar v-if="showBar.b3" :bar="b3bar" :show-bar="showBar.b3" :path-enter="pathEnter" :map-id="5" :path-leave="pathLeave" :path-click="pathClick"/>

            <SvgBar v-if="showBar.b4" :bar="b4bar" :show-bar="showBar.b4" :path-enter="pathEnter" :map-id="4" :path-leave="pathLeave" :path-click="pathClick"/>
        </g>
        <g class="bars_mobile d-block d-md-none">
            <SvgBar v-if="showBar.b1" :bar="b1barMobile" :show-bar="showBar.b1" :path-enter="pathEnter" :map-id="7" :path-leave="pathLeave"/>

            <SvgBar v-if="showBar.b2" :bar="b2barMobile" :show-bar="showBar.b2" :path-enter="pathEnter" :map-id="1" :path-leave="pathLeave" :path-click="pathClick"/>

            <SvgBar v-if="showBar.b3" :bar="b3barMobile" :show-bar="showBar.b3" :path-enter="pathEnter" :map-id="5" :path-leave="pathLeave" :path-click="pathClick"/>

            <SvgBar v-if="showBar.b4" :bar="b4barMobile" :show-bar="showBar.b4" :path-enter="pathEnter" :map-id="4" :path-leave="pathLeave" :path-click="pathClick"/>
        </g>
        </svg>
    </div>
</template>

<script>
import SvgBar from "@/components/maps/SvgBar";
import {mapActions} from "vuex";
import store from "@/store";
let hoverIntent = null
export default {
    components: {SvgBar},
    data() {
        return {
            mapUrl: '/map/home-map.jpg',
            showMap: false,
            showBar: {
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                bJazierko: false
            },
            b1bar: {
                left: 1360,
                top: 600,
                stage: "bory1",
                tiles: [
                {
                    title1: "ETAPA",
                    title2: "UKONČENÁ"
                }
                ]
            },
            jazierkoBar: {
                left: 480,
                top: 405,
                stage: "bory2b",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/bory2#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory2#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory2#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory2#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory2#tabulka"
                },
                ]
            },
            b2bar: {
                left: 700,
                top: 350,
                stage: "bory2b",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },
                ]
            },

            b3bar: {
                left: 1400,
                top: 820,
                stage: "bory3",
                tiles: [
                {
                    title1: "ETAPA",
                    title2: "UKONČENÁ"
                }
                ]
            },
            b4bar: {
                left: 600,
                top: 200,
                stage: "bory4",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },
                ]
            },
            b1barMobile: {
                left: 830,
                top: 420,
                stage: "bory1",
                tiles: [
                {
                    title1: "ETAPA",
                    title2: "UKONČENÁ"
                }
                ]
            },
            b2barMobile: {
                left: 450,
                top: 160,
                stage: "bory2b",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/novy-dvor#tabulka"
                },
                ]
            },

            b3barMobile: {
                left: 450,
                top: 400,
                stage: "bory3",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/bory3#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "vypredané",
                    route: "/byvanie/bory3#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "vypredané",
                    route: "/byvanie/bory3#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory3#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/bory3#tabulka"
                },
                ]
            },
            b4barMobile: {
                left: 450,
                top: 400,
                stage: "bory4",
                tiles: [
                {
                    title1: "VŠETKY",
                    title2: "BYTY",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "1 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "2 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "3 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },{
                    title1: "4 IZBOVÉ",
                    title2: "BYTY",
                    subtitle: "",
                    route: "/byvanie/na-hradzi#tabulka"
                },
                ]
            },
        }
    },
    async mounted() {
        await this.loadFlats()
        this._loadFlatCounts()
    },
    methods: {
        ...mapActions('flats', ['loadFlats']),
        pathEnter: function (oEvent, iMapId) {
            const mapId = iMapId || oEvent.target.dataset.map
            if(!mapId)
                return

            this._showBar(mapId)
            clearTimeout(hoverIntent)
        },

        pathLeave: function () {
            hoverIntent = setTimeout(()=>  {
                this.showMap = false
                this._hideBar()
            }, 500)
            document.getElementById('stvrt-default').classList.remove('d-none')
            document.getElementById('stvrt-default').classList.add('d-block')
            document.querySelectorAll('.stvrt-img').forEach((e) => {
                e.classList.remove('d-block')
            })
        },
        pathClick: function (oEvent) {
            const route = oEvent.target.dataset.route
                || oEvent.target.parentElement.dataset.route
            const stage = oEvent.target.dataset.stage
                || oEvent.target.parentElement.dataset.stage
            const title = oEvent.target.dataset.title
                || oEvent.target.parentElement.dataset.title
            const titleParts = title ? title.split(" ") : []

            if(route)
                if (this.$route.name === 'Flats') {
                this.$router.push(route + '#mapbox')
                } else {
                this.$router.push(route)
                }

            if(title && titleParts.length > 1 && stage)
                setTimeout(() => {
                this.$root.$emit('setFilter',
                    {
                        stages: stage,
                        dates: [],
                        buildings: [],
                        rooms: titleParts[0] == "1" ? ["1", "1,5"] : [ titleParts[0] ],
                        floors: [],
                        availability: 0,
                        priceVat: null,
                        totalArea: null
                    }
                )
                }, )


        },
        _showBar(mapId) {
            this._hideBar()

            if(mapId == 7) {
                this.showBar.b1 = true
                document.getElementById('stvrt-default').classList.remove('d-block')
                document.getElementById('stvrt-default').classList.add('d-none')
                document.getElementById('prvedomy').classList.add('d-block')
            }
            if(mapId == 1) {
                this.showBar.b2 = true
                document.getElementById('stvrt-default').classList.remove('d-block')
                document.getElementById('stvrt-default').classList.add('d-none')
                document.getElementById('novydvor').classList.add('d-block')
            }
            // if(mapId == 2)
            //   this.showBar.bJazierko = true
            if(mapId == 5) {
                this.showBar.b3 = true
                document.getElementById('stvrt-default').classList.remove('d-block')
                document.getElementById('stvrt-default').classList.add('d-none')
                document.getElementById('promenada').classList.add('d-block')
            }
            if(mapId == 4) {
                this.showBar.b4 = true
                document.getElementById('stvrt-default').classList.remove('d-block')
                document.getElementById('stvrt-default').classList.add('d-none')
                document.getElementById('hradza').classList.add('d-block')
            }
        },
        _hideBar() {
            Object.keys(this.showBar).forEach( k => this.showBar[k] = false )
        },
        showImg(img) {
            // if (img == 'novydvor') {
            //     this.showBar.b2 = true
            // }
            // if (img == 'centrum') {
            //     document.getElementById('Path1').classList.add('d-none')
            // } else {
            //     document.getElementById('Path1').classList.remove('d-none')
            // }
            if (img == 'prvedomy') {
                this._hideBar()
                this.showBar.b1 = true
                clearTimeout(hoverIntent)
            }
                
            if (img == 'promenada') {
                this._hideBar()
                this.showBar.b3 = true
                clearTimeout(hoverIntent)
            }
            if (img == 'novydvor') {
                this._hideBar()
                this.showBar.b2 = true
                clearTimeout(hoverIntent)
            }
            if (img == 'hradza') {
                this._hideBar()
                this.showBar.b4 = true
                clearTimeout(hoverIntent)
            }
            document.getElementById('stvrt-default').classList.remove('d-block')
            document.getElementById('stvrt-default').classList.add('d-none')
            document.getElementById(img).classList.add('d-block')
        },
        setDefault(img) {
            hoverIntent = setTimeout(()=>  {
                this.showMap = false
                this._hideBar()
            }, 500)
            document.getElementById('stvrt-default').classList.remove('d-none')
            document.getElementById('stvrt-default').classList.add('d-block')
            document.getElementById(img).classList.remove('d-block')
        },
        _loadFlatCounts() {
            this._loadFlatCountsByStage("bory2b", "b2bar")
            this._loadFlatCountsByStage("bory3", "b3bar")
            this._loadFlatCountsByStage("bory4", "b4bar")
        },

        _loadFlatCountsByStage(stage, variable) {
            const count = store.getters["flats/getFreeFlatsBySize"](stage)

            Object.keys(count).forEach( size => {
                this[variable].tiles.forEach( (k, i) => {
                if(k.title1.split(" ")[0] == size)
                    this[variable].tiles[i].subtitle = "voľné byty: " + count[size]

                })
            })

            this.$forceUpdate()
        }
    }
}
</script>

<style lang="sass">
$md: 768px

.stvrt-img
    transition: opacity 0.1s linear
.new-map
    position: relative
    background: var(--darkBlue)

    .badge
        position: absolute
        width: 200px
        height: 200px
        border-radius: 50%
        background: var(--blue)
        display: flex
        align-items: center
        justify-content: center
        font-size: 26px
        line-height: 30px
        color: #fff
        text-transform: uppercase
        font-weight: 900
        transform: rotate(-20deg)
        right: 20%
        bottom: 20%

    @media (max-width: $md)
        height: 380px
        overflow: hidden

        img
            transform: scale(2.35) translate(50px, 74px)


    img
      width: 100%

    .bars_mobile
        @media (max-width: $md)
            transform: scale(3) translate(-380px, -180px)

    .bars,
    .bars_mobile
        .bar
            cursor: pointer
            fill: black
            transition: opacity 0.1s linear
            opacity: 0
            g rect
                transition: all 0.1s linear
                cursor: pointer
            g:hover rect
                fill: #2D323A

            &.showBar
                opacity: 1
            text
                fill: white
                font-family: objektiv-mk2,sans-serif
                font-size: 100%
                &.subtitle
                    font-size: 80%

            .bory1
                rect
                    fill: #f8cd2a
                &:hover rect
                    fill: rgba(248, 205, 42, 0.8)
                text
                    fill: #001F33

            .bory2, .bory2b
                rect
                    fill: #83d0f0
                &:hover rect
                    fill: rgba(131, 208, 240, 0.8)
                text
                    fill: #001F33

            .bory3
                rect
                    fill: #688495
                &:hover rect
                    fill: rgba(104, 132, 149, 0.8)

            .bory4
                rect
                    fill: #6fbda8
                &:hover rect
                    fill: rgba(111, 189, 168, 0.8)
                text
                    fill: #001F33

            .jazierko
                rect
                    fill: #83d0f0
                &:hover rect
                    fill: rgba(131, 208, 240, 0.8)
    svg
        max-width: 100%
        height: auto
        position: relative
        z-index: 99

    .overlay
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        z-index: 999

        @media (max-width: $md)
            #Layer_2_mobile
                transform: scale(2.35) translate(-380px, -180px)
</style>