<template>
  <div class="flats-page">
    <HomeMap />
    <section class="flats" id="tabulka">
      <FlatsTable :show-vr="true"></FlatsTable>
    </section>
  </div>
</template>

<script>

import FlatsTable from "@/components/FlatsTable";
import HomeMap from "@/components/maps/HomeMapNew";

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
  name: "Flats",
  components: {HomeMap, FlatsTable},
  mounted() {
    window.scrollTo(0, 0)
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.temp-block
  background: var(--blue)

  h2
    font-weight: bold
    font-size: 80px
    line-height: 90px

    @media (max-width: $md)
      font-size: 46px
      line-height: 54px

  p
    font-size: 20px
    line-height: 24px

    a
      font-weight: 900
      color: #fff
      text-decoration: underline

  .img
    width: 100%
    height: 100%
    background: url("../assets/images/novy-rok-stare-ceny.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

.flats-page
  padding-top: 85px
  background: #2D323A

  &.withTopBar
    padding-top: 127px

  @media (max-width: $md)
    padding-top: 45px

</style>
